import styled from '@emotion/styled';
import OBR from '@owlbear-rodeo/sdk';
import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { getAppID } from '../appId';
import { Creature } from '../Helpers/CreatureTypes';
import { API_ROOT } from '../Helpers/Endpoints';
import { useQueryClient } from '@tanstack/react-query';
import { GetCustomCreaturesQuery } from '../Main/Hooks/useGetCustomCreatures';
import Select from "react-select"

const StyledModal = styled.div({
    width: '400px',
    height: '300px',
    overflow: 'hidden',
    padding: '20px'
})

const HeaderGroup = styled.div({
    width: '100%',
    height: '120px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
})

const CharacterGroup = styled.div({
    width: '220px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

const StyledInputGroup = styled.div({
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    '& label': {
        color: '#eee',
        fontWeight: 'bold'
    },
    '& input': {
        height: '26px',
        fontSize: '14px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#ddd',
        border: 'none',
        borderBottom: '1px solid #aaa'
    }
})

const InputGroup = (
    {label, name, placeholder, value, callback}: 
    {label: string, name: string, placeholder: string, value?: number|string, callback: (event: any) => void}): ReactElement => {
    return (
        <StyledInputGroup>
            <label>{label}</label>
            <input type="text" value={value} name={name} placeholder={placeholder} onChange={callback}/>
        </StyledInputGroup>
    )
}

const HiddenInput = styled.input({
    display: 'none'
})

const TokenButton = styled.button(({hasFile}: {hasFile: boolean}) =>({
    width: '120px',
    height: '120px',
    backgroundColor: hasFile ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.1)',
    borderRadius: '5px',
    position: 'relative',
    border: 'none',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0.25)'
    },
    '& img': {
        width: '100px',
        height: '100px',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10
    }
}))

const PlaceholderSpan = styled.span(({hasFile}: {hasFile: boolean}) => ({
    color: '#fff',
    display: hasFile ? 'none' : 'block'
}));

const ImageInput = ({file, callback}: {file: string|undefined, callback: (event: any) => void}): ReactElement => {
    const uploadRef = useRef<HTMLInputElement>(null);

    const handleUpload = useCallback(() => {
        if (uploadRef?.current) {
            uploadRef.current.click()
        }
    }, [uploadRef])
    return (
        <TokenButton hasFile={!!file} onClick={handleUpload}>
            { file && <img src={file} />}
            <HiddenInput ref={uploadRef} type="file" accept="image/jpg, image/png, image/jpeg" onChange={callback}/>
            <PlaceholderSpan hasFile={!!file}>Click here to upload file</PlaceholderSpan>
        </TokenButton>
    )
}

const TypeSelector = ({type, options, callback}: {type: {label: string, value: string}, options: {label: string, value: string}[], callback: (e: any) => void}) => {
    const SelectStyles = {
        control: (styles: any) => ({
            ...styles,
            width: '80%',
            border: '1px solid rgba(0,0,0,0.25)',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0)'
        }),
        menu: (styles: any, state: any) => ({
            ...styles,
            width: '80%',
            color: '#fff',
            backgroundColor: 'rgb(30,34,49)',
        }),
        input: (styles: any) => ({
            ...styles,
            color: '#ff',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: '#ff',
        }),
        option: (styles: any) => ({
            ...styles,
            color: '#fff',
            backgroundColor: 'rgb(30,34,49)',
            ':hover': {
                backgroundColor: 'rgb(50,50,75)'
            }
        }),
        singleValue: (styles: any) => ({
            ...styles,
            color: 'white'
        })
    }

    const StyledSelect = styled(Select)({
        width: '100%'
    })

    return (
        <StyledSelect value={type} styles={SelectStyles} onChange={callback} options={options}/>
    )
}

interface NewCreature
{
    name: string,
    type: string,
    stats: string,
    size: string,
    image: Blob
}

export const NewCreatureModal = (): ReactElement => {
    const queryClient = useQueryClient();
    const [creature, setCreature] = useState<Partial<NewCreature>>({size: 'medium'})
    const [image, setImage] = useState<string>('')

    const setAttribute = useCallback(({name, value}:{name: string, value: string | number | boolean}) => {
        setCreature({
            ...creature,
            [name]: value
        })
    }, [creature, setCreature])

    const updateText = useCallback((event: any) => {
        if (event?.target) {
            setAttribute({name: event.target?.name, value: event.target?.value});
        }
    }, [creature])

    const submitCreature = useCallback(async () => {
        const roomId = OBR.room.id;
        const formData = new FormData();
        // for (const [key, value] of Object.entries(creature)) {
        //     formData.append(key, value)
        // }

        console.log('[DEBUG]', creature)
        if (creature.name) {
            formData.append('name', creature.name)
        }

        if (creature.image) {
            formData.append('image', creature.image)
        }

        if (creature.size) {
            formData.append('size', creature.size)
        }

        if (creature.stats) {
            formData.append('stats', creature.stats)
        }

        if (creature.type) {
            formData.append('type', creature.type)
        }

        formData.append('room_id', roomId)

        await fetch(`${API_ROOT}/v1/custom-creatures`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
            },
            body: formData
        })
        .then(response => {
            if (response.status === 200) {
                closeModal()
            }
        })
    }, [creature, queryClient])

    const handleFileUpload = useCallback(async (event: any) => {
        setImage(URL.createObjectURL(event.target.files[0]))
        setCreature({
            ...creature,
            image: event.target.files[0]
        })
    }, [creature, setCreature])

    const closeModal = useCallback(async () => {
        await OBR.room.setMetadata({[`${getAppID()}/modal`]: false})
        await OBR.modal.close(`${getAppID()}/new-creature`)
    }, [])
    
    function makeUppercase(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    const selectedValue = useMemo(() => {
        return {label: makeUppercase(creature?.size ?? ''), value: creature?.size ?? ''}
    }, [creature.size])

    const options = useMemo(() => {
        return [
            {label: 'Small', value: 'small'},
            {label: 'Medium', value: 'medium'},
            {label: 'Large', value: 'large'},
            {label: 'Huge', value: 'huge'},
            {label: 'Gargantuan', value: 'gargantuan'},
        ]
    }, [])

    const handleSelectChange = useCallback((event: any) => {
        if (event) {
            setCreature({
                ...creature,
                size: event.value
            })
        }
    }, [creature, setCreature])

    return (
        <StyledModal>
            <HeaderGroup>
                <ImageInput file={image} callback={handleFileUpload} />
                <CharacterGroup>
                    <InputGroup label="Creature Name" name="name" placeholder="Creature name..." value={creature.name} callback={updateText}/>
                    <TypeSelector type={selectedValue} options={options} callback={handleSelectChange}/>
                </CharacterGroup>
            </HeaderGroup>
            <InputGroup label="Creature Stats URL" name="stats" placeholder="Creature stats..." value={creature.stats} callback={updateText}/>
            <InputGroup label="Creature Type" name="type" placeholder="Creature Type..." value={creature.type} callback={updateText}/>
            <button onClick={submitCreature}>Submit</button>
            <button onClick={closeModal}>Close</button>
        </StyledModal>
    )
}