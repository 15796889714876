import { useEffect, useState } from "react";
import OBR from "@owlbear-rodeo/sdk";
import { StillLoading } from "./Header/StillLoading";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { NewCreatureModal } from "./Modals/NewCreatureModal";
import { BeyondTokens } from "./Main/BeyondTokens";
import { ImportCreaturesModal } from "./Modals/ImportCreaturesModal";

function App() {
  const [sceneReady, setSceneReady] = useState(false);

  useEffect(() => {
    try {
      OBR.onReady(() => setSceneReady(true))
    } catch (err) {}
  }, [])

  function Main() {
    if (sceneReady) {
      return <BeyondTokens />
    } else {
      return <StillLoading />
    }
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/new-creature" element={<NewCreatureModal />}/>
        <Route path="/import-creature" element={<ImportCreaturesModal />}/>
      </Routes>
    </Router>
  )
}

export default App;
