import OBR from "@owlbear-rodeo/sdk"
import { ReactElement, useCallback, useState } from "react"
import { getAppID } from "../appId"
import { API_ROOT } from "../Helpers/Endpoints"
import styled from "@emotion/styled"

const StyledModal = styled.div({
    width: '400px',
    height: '100px',
    overflow: 'hidden',
    padding: '20px'
})

const StyledInputGroup = styled.div({
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    '& label': {
        color: '#eee',
        fontWeight: 'bold'
    },
    '& input': {
        height: '26px',
        fontSize: '14px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#ddd',
        border: 'none',
        borderBottom: '1px solid #aaa'
    }
})

const InputGroup = (
    {label, name, placeholder, value, callback}: 
    {label: string, name: string, placeholder: string, value?: number|string, callback: (event: any) => void}): ReactElement => {
    return (
        <StyledInputGroup>
            <label>{label}</label>
            <input type="text" value={value} name={name} placeholder={placeholder} onChange={callback}/>
        </StyledInputGroup>
    )
}

export const ImportCreaturesModal = (): ReactElement => {
    const [roomId, setRoomId] = useState<string>('')

    const updateText = useCallback((event: any) => {
        if (event?.target) {
            setRoomId(event.target?.value)
        }
    }, [])

    const handleImport = useCallback(async () => {
        const myRoomId = OBR.room.id
        const body = {
            'room_id': myRoomId,
            'import_id': roomId,
        }

        await fetch(`${API_ROOT}/v1/custom-creatures/import`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.status === 204) {
                closeModal()
            }
        })
    }, [roomId])

    const closeModal = useCallback(async () => {
        await OBR.room.setMetadata({[`${getAppID()}/modal`]: false})
        await OBR.modal.close(`${getAppID()}/import-creature`)
    }, [])
    return (
        <StyledModal>
            <InputGroup label="Room id" name="room-id" placeholder="Room id..." value={roomId} callback={updateText} />
            <button onClick={handleImport}>Save</button>
            <button onClick={closeModal}>Close</button>
        </StyledModal>
    )
}