import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import styled from '@emotion/styled';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const StyledApp = styled(App)({
  width: '400px',
  height: '600px',
  boxSizing: 'border-box',
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledApp />
    </QueryClientProvider>
  </React.StrictMode>
);
