import styled from "@emotion/styled"

export const Container = styled.div({
    width: '100%',
    height: '490px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
})

export const StyledFooter = styled.div({
    display: 'flex',
    width: '100%',
    padding: '0px 10px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '30px',
    marginBottom: '10px',
})

export const Wrapper = styled.div(({isLoading}: {isLoading: boolean}) => ({
    width: '100%',
    height: '430px',
    opacity: isLoading ? 0.5 : 1,
    pointerEvents: isLoading ? 'none' : 'all',
    overflowY: 'auto',
}))

export const Header = styled.div({
    width: '100%',
    height: '60px',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const Footer = styled.div({
    width: '100%',
    height: '60px',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const PrimaryButton = styled.button({
    width: '90px',
    height: '40px',
    border: 'none',
    fontSize: '0.75em',
    fontWeight: 'bold',
    borderRadius: '5px',
    color: '#fff',
    backgroundColor: 'rgb(66, 102, 245)',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgb(51, 80, 196)'
    },
    '&:active': {
        cursor: 'pointer',
        backgroundColor: 'rgb(38, 60, 150)'
    }
})

export const StyledButton = styled.button({
    width: '90px',
    height: '40px',
    border: 'none',
    fontSize: '0.75em',
    fontWeight: 'bold',
    borderRadius: '5px',
    color: '#fff',
    backgroundColor: 'rgb(77, 77, 77)',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgb(50, 50, 50)'
    },
    '&:active': {
        cursor: 'pointer',
        backgroundColor: 'rgb(40, 40, 40)'
    }
})

export const CreatureGrid = styled.div({
    width: '100%',
    height: '100%',
    padding: '0px 12px',
})