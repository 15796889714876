import { useQuery } from "@tanstack/react-query";
import { API_ROOT } from "../../Helpers/Endpoints";
import OBR from "@owlbear-rodeo/sdk";
import { Creature, LargeCreature, MediumCreature, SmallCreature } from "../../Helpers/CreatureTypes";

export const GetCustomCreaturesQuery = 'creatures/custom'

interface CustomCreatureResource {
    id: number,
    name: string,
    size: string,
    type: string,
    image: string,
    stats: string,
    is_import: boolean
}

export const useGetCustomCreatures = () => {
    return useQuery({
        queryKey: ['creatures/custom'],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const roomId = OBR.room.id;
            return fetch(`${API_ROOT}/v1/custom-creatures?room_id=${roomId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "cache-control": "no-cache"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (!data?.items) {
                    return [];
                }

                const creatures: Creature[] = [];
                data.items.forEach((creature: CustomCreatureResource) => {
                    let filteredCreature = {};
                    switch (creature.size) {
                        case 'small':
                            filteredCreature = SmallCreature
                            break
                        case 'medium':
                            filteredCreature = MediumCreature
                            break
                        case 'large':
                            filteredCreature = LargeCreature
                            break
                        default:
                            filteredCreature = MediumCreature
                            break
                    }

                    creatures.push({
                        ...filteredCreature,
                        id: creature.id,
                        name: creature.name,
                        size: creature.size,
                        type: creature.type,
                        image: creature.image,
                        stats: creature.stats,
                        is_import: creature.is_import
                    } as Creature)
                })

                return creatures;
            })
        }
    })
}