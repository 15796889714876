import styled from "@emotion/styled"
import { Dispatch, SetStateAction, useCallback } from "react"

const StyledContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
})

const StyledName = styled.label({
    marginRight: '5px'
})

const StyledLabel = styled.label(({isActive}: {isActive: boolean}) => ({
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '24px',
    backgroundColor: isActive ? 'rgb(66, 135, 245)' : 'rgba(0,0,0,0.25)',
    borderRadius: '16px',

    '& input': {
        opacity: 0,
        width: 0,
        height: 0
    }
}))

const StyledSpan = styled.span(({isActive}: {isActive: boolean}) => ({
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0)',

    '&:before': {
        position: 'absolute',
        content: "''",
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        left: '2px',
        bottom: '2px',
        backgroundColor: 'rgb(20, 23, 33)',
        transition: '0.4s',
        transform: isActive ? 'translateX(16px)' : 'translateX(0px)'
    }
}))

export const ToggleSwitch = ({label, isActive, update}: {label: string, isActive: boolean, update: Dispatch<SetStateAction<boolean>>}) => {
    const handleToggle = useCallback(() => {
        update(!isActive)
    }, [isActive, update])
    
    return (
        <StyledContainer>
            <StyledName>{label}</StyledName>
            <StyledLabel isActive={isActive}>
                <input onChange={handleToggle} type="checkbox" />
                <StyledSpan isActive={isActive} />
            </StyledLabel>
        </StyledContainer>
    )
}