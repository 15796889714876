import styled from "@emotion/styled"

const StyledContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    padding: '10px',
    color: '#ddd',
})

export const StillLoading = () => {
    return (
        <StyledContainer>Still loading...</StyledContainer>
    )
}