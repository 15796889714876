import react, {ReactElement, useState, useCallback, useEffect, useRef} from 'react'
import { Creature } from '../Helpers/CreatureTypes'
import { API_ROOT } from '../Helpers/Endpoints';
import OBR from '@owlbear-rodeo/sdk';
import { Container, CreatureGrid, Footer, PrimaryButton, StyledButton, StyledFooter, Wrapper } from './styled/SectionStyles';
import { getAppID } from '../appId';
import { TokenOption } from '../CharacterSelector/TokenOption';
import { TokenContextMenu } from '../CharacterSelector/TokenContextMenu';

import { Filter, LinkButton, PlusButton } from "../rec/svg";
import { GetCustomCreaturesQuery, useGetCustomCreatures } from './Hooks/useGetCustomCreatures';
import { useQueryClient } from '@tanstack/react-query';
import { handleOBRAdd } from '../Helpers/OBRHelpers';
import { ToggleSwitch } from '../CharacterSelector/ToggleSwitch';

export interface ContextMenu {
    creature: Creature|null,
    position: {
        x: number,
        y: number
    },
    toggled: boolean,
    buttons: ContextButton[]|null
}

export interface ContextButton {
    text: string,
    icon: () => ReactElement|null,
    onClick: () => void
}

export const CustomCreatures = (): ReactElement => {
    const queryClient = useQueryClient()
    const [tokenHidden, setTokenHidden] = useState<boolean>(false)

    const contextMenuRef = useRef<HTMLDivElement|null>(null)
    const containerRef = useRef<HTMLDivElement|null>(null)
    const [contextMenu, setContextMenu] = useState<ContextMenu>({
        creature: null,
        position: {
            x: 0,
            y: 0,
        },
        toggled: false,
        buttons: null
    })

    const {data, isLoading, isFetching} = useGetCustomCreatures()
    const creatures = data ?? []

    const deleteCreature = useCallback(async (creature: Creature) => {
        const roomId = OBR.room.id;

        console.log('DEBUG', creature)
        
        if (creature.is_import) {
            // Remove import
            fetch(`${API_ROOT}/v1/custom-creatures/import/${creature.id}`, {
                method: 'DELETE',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json'
                },
                body: JSON.stringify({room_id: roomId})
            })
            .then(response => {
                if (response.status !== 204) {
                    alert('Failed to delete creature')
                }
                queryClient.invalidateQueries({queryKey: ['creatures/custom']})
                resetContextMenu()
            })
        } else {
            fetch(`${API_ROOT}/v1/custom-creatures/${creature.id}`, {
                method: 'DELETE',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json'
                },
                body: JSON.stringify({room_id: roomId})
            })
            .then(response => {
                if (response.status !== 204) {
                    alert('Failed to delete creature')
                }
                queryClient.invalidateQueries({queryKey: ['creatures/custom']})
                resetContextMenu()
            })
        }
    }, [queryClient])

    const handleCreateNewClick = useCallback(async () => {
        await OBR.room.setMetadata({[`${getAppID()}/modal`]: true})
        return await OBR.modal.open({
            id: `${getAppID()}/new-creature`,
            url: '/new-creature',
            width: 400,
            height: 300
        })
    }, [])

    const handleImportClick = useCallback(async () => {
        await OBR.room.setMetadata({[`${getAppID()}/modal`]: true})
        return await OBR.modal.open({
            id: `${getAppID()}/import-creature`,
            url: '/import-creature',
            width: 400,
            height: 100
        })
    }, [])

    const handleAdd = useCallback(async (creature: Creature) => {
        await handleOBRAdd(creature, false, tokenHidden)
        resetContextMenu()
    }, [tokenHidden])

    const handleContext = useCallback(async (e: react.MouseEvent<Element, MouseEvent>, creature: Creature) => {
        e.preventDefault()
        const isGM = await OBR.player.getRole() === 'GM';

        if (!isGM) {
            return
        }

        let contextMenuWidth = contextMenuRef.current?.getBoundingClientRect().width ? contextMenuRef.current?.getBoundingClientRect().width : 150
        const parentTop = containerRef.current?.parentElement?.getBoundingClientRect().top ?? 0
        const parentOffset = containerRef.current?.parentElement?.offsetTop ?? 40
        const isLeft = e.clientX < (window.innerWidth / 2)

        let x
        let y = e.clientY - (parentTop - parentOffset)

        if (isLeft) {
            x = e.clientX
        } else {
            x = e.clientX - contextMenuWidth
        }

        setContextMenu({
            creature,
            position: {
                x,
                y
            },
            toggled: true,
            buttons: [
                {
                    text: 'Add token',
                    icon: PlusButton,
                    onClick: () => handleAdd(creature)
                },
                {
                    text: 'Stat block',
                    icon: LinkButton,
                    onClick: () => { 
                        window.open(creature.stats, '_blank')
                        resetContextMenu()
                    }
                },
                {
                    text: 'Delete',
                    icon: LinkButton,
                    onClick: () => deleteCreature(creature)
                }
            ]
        })
    }, [handleAdd, deleteCreature])

    function resetContextMenu() {
        setContextMenu({
            creature: null,
            position: {
                x: 0,
                y: 0,
            },
            toggled: false,
            buttons: null
        })
    }

    useEffect(() => {
        function handler(e: any) {
            if (contextMenuRef.current) {
                if(!contextMenuRef.current.contains(e.target)) {
                    resetContextMenu()
                }
            }
        }

        document.addEventListener('click', handler)
        
        return () => {
            document.removeEventListener('click', handler)
        }
    })

    useEffect(() => {
        OBR.room.onMetadataChange((metadata) => {
            if (metadata[`${getAppID()}/modal`] == false) {
                queryClient.invalidateQueries({queryKey: [GetCustomCreaturesQuery]})
            }
        });
    }, [])

    return (
        <Container>
            <Footer>
                <PrimaryButton onClick={handleCreateNewClick}>New</PrimaryButton>
                <StyledButton onClick={handleImportClick}>Import</StyledButton>
                <StyledButton onClick={() => queryClient.invalidateQueries({queryKey: ['creatures/custom']})}>Refresh</StyledButton>
            </Footer>
            <Wrapper isLoading={isLoading || isFetching}>
                <CreatureGrid>
                    {creatures.map((creature: Creature) => <TokenOption key={creature.id} token={creature} handleAdd={handleAdd} updateContextMenu={(event) => handleContext(event, creature)}/>)}
                </CreatureGrid>
            </Wrapper>
            <StyledFooter>
                <ToggleSwitch label="Hide Token" isActive={tokenHidden} update={setTokenHidden}/>
            </StyledFooter>
            <TokenContextMenu 
                rightClickItem={contextMenu.creature}
                positionX={contextMenu.position.x}
                positionY={contextMenu.position.y}
                isToggled={contextMenu.toggled} 
                buttons={contextMenu.buttons} 
                contextMenuRef={contextMenuRef}
            />
        </Container>
    )
}