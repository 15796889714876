import styled from '@emotion/styled';
import React, {ReactElement, useState, useCallback, useMemo, useEffect} from 'react';
import { Compendium } from './Compendium';
import OBR, { ContextMenuContext, Item, Vector2 } from '@owlbear-rodeo/sdk';
import { CustomCreatures } from './CustomCreatures';
import { getAppID } from '../appId';

import link from "../rec/link.svg";
import plus from "../rec/plus.svg";
import { CreatureScale, DEFAULT_TOKEN_SIZE } from '../Helpers/OBRHelpers';
import { ToggleSwitch } from '../CharacterSelector/ToggleSwitch';

const StyledContainer = styled.div({
    width: '400px',
    height: '630px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

const StyledHeader = styled.div({
    width: '100%',
    height: '60px',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
})

const StyledTitle = styled.h3({
    maring: 0,
    color: '#fff'
})

const RoomIdButton = styled.button({
    color: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    '&:hover': {
        cursor: 'pointer'
    }
})

const TabsContainer = styled.div({
    width: 'calc(100% - 20px)',
    height: '40px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
})

const Tab = styled.button(({isActive}: {isActive: boolean}) => ({
    width: '100px',
    height: '40px',
    backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)',
    border: 'none',
    color: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.25)',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0.25)'
    }
}))

type TabOptions = 'compendium' | 'custom'

export const BeyondTokens = (): ReactElement => {
    const [currTab, setTab] = useState<TabOptions>('compendium')

    const copyRoomId = useCallback(async () => {
        const roomId = await OBR.room.id;
        navigator.clipboard.writeText(roomId);
        console.log(await OBR.room)
    }, [])

    const syncPlayerViews = useCallback(async (context: ContextMenuContext) => {
        const isGM = await OBR.player.getRole() === 'GM'
        const item = context?.items[0] ?? {} as Item

        const creatureScale = item.metadata[`${getAppID()}/creature-scale`] as CreatureScale

        // Move GM Screen
        await OBR.viewport.setScale(1);
        const screenScale = await OBR.viewport.getScale()
        const itemPosition = item.position;

        const screenWidth = await OBR.viewport.getWidth()
        const screenHeigt = await OBR.viewport.getHeight()

        await OBR.viewport.animateTo({position: {
            // -(Item Positon - ((screenwidth - (tokenDIP / 2) / 2))
            x: -(itemPosition.x - ((screenWidth - (DEFAULT_TOKEN_SIZE / 4) * creatureScale.x) / 2)),
            y: -(itemPosition.y - ((screenHeigt - (DEFAULT_TOKEN_SIZE / 4) * creatureScale.y) / 2))
        }, scale: screenScale});

        setTimeout(() => {
            if (isGM) {
                OBR.player.setSyncView(true);
                setTimeout(() => {
                    OBR.player.setSyncView(false);
                }, 2000)
            }
        }, 500)
    }, []);

    useEffect(() => {
        OBR.contextMenu.create({
            icons: [
                {
                    icon: plus,
                    label: "Add character sheet",
                    filter: {
                        every: [
                            {key: "layer", value: "CHARACTER"},
                            {key: ["metadata", `${getAppID()}/char-sheet`], value: undefined},
                            {key: ["metadata", `${getAppID()}/hidden`], value: undefined}
                        ],
                        permissions: ["UPDATE"],
                    },
                },
                {
                    icon: link,
                    label: "View character sheet",
                    filter: {
                        every: [
                            {key: "layer", value: "CHARACTER"},
                            {key: ["metadata", `${getAppID()}/hidden`], value: undefined}
                        ]
                    }
                },
                {
                    icon: link,
                    label: "View stat block",
                    filter: {
                        every: [
                            {key: "layer", value: "CHARACTER"},
                            {key: ["metadata", `${getAppID()}/hidden`], value: true}
                        ],
                        permissions: ["CREATE"],
                    }
                },
            ],
            id: `${getAppID()}/context-menu`,
            onClick(context) {
                const hasUrl = context.items.every(
                    (item) => item.metadata[`${getAppID()}/char-sheet`] !== undefined
                );
                if (!hasUrl) {
                    const charUrl = window.prompt("Enter the D&D Beyond link") ?? '';
                    OBR.scene.items.updateItems(context.items, (items) => {
                        items.forEach((item) => {
                            console.log(item)
                            item.metadata[`${getAppID()}/char-sheet`] = charUrl
                        })
                    })
                } else {
                    context.items.every(
                        (item) => window.open(item.metadata[`${getAppID()}/char-sheet`] as string, '_blank')
                    );
                }
            }
        })

        OBR.contextMenu.create({
            icons: [
                {
                    icon: plus,
                    label: "Focus here",
                    filter: {
                        permissions: ["MAP_CREATE"],
                    },
                },
            ],
            id: `${getAppID()}/focus-menu`,
            onClick(context) {
                syncPlayerViews(context)
            }
        })
    }, [syncPlayerViews])
    return (
        <StyledContainer>
            <StyledHeader>
                <StyledTitle>Beyond Tokens</StyledTitle>
                <RoomIdButton onClick={copyRoomId}>Copy room id</RoomIdButton>
            </StyledHeader>
            <TabsContainer>
                <Tab isActive={currTab === 'compendium'} onClick={() => setTab('compendium')}>Compendium</Tab>
                <Tab isActive={currTab === 'custom'} onClick={() => setTab('custom')}>Custom</Tab>
            </TabsContainer>
            {currTab === 'compendium' ? 
                <Compendium /> :
                <CustomCreatures />
            }
        </StyledContainer>
    )
}