export const SmallCreature = {
    width: 300,
    height: 300,
    offsetX: 150,
    offsetY: 150,
    scaleX: 0.5,
    scaleY: 0.5
}

export const MediumCreature = {
    width: 300,
    height: 300,
    offsetX: 0,
    offsetY: 0,
    scaleX: 1,
    scaleY: 1
}

export const LargeCreature = {
    width: 300,
    height: 300,
    offsetX: 0,
    offsetY: 0,
    scaleX: 2,
    scaleY: 2
}

export const HugeCreature = {
    width: 300,
    height: 300,
    offsetX: 0,
    offsetY: 0,
    scaleX: 3,
    scaleY: 3
}

export const GargantuanCreature = {
    width: 300,
    height: 300,
    offsetX: 0,
    offsetY: 0,
    scaleX: 4,
    scaleY: 4
}

export interface Creature {
    id: number,
    name: string,
    size: string,
    type: string,
    image: string,
    stats: string,
    width: number,
    height: number,
    offsetX: number,
    offsetY: number,
    scaleX: number,
    scaleY: number,
    is_import: boolean,
}