import styled from "@emotion/styled"
import { Creature } from "../Helpers/CreatureTypes"
import { MouseEvent } from "react"

const TokenButton = styled.button({
    width: '100px',
    height: '120px',
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    margin: '12px',
    '&:hover': {
        cursor: 'pointer',
    }
})

const TokenImage = styled.img({
    width: '100px',
    height: '100px',
})

const TokenName = styled.h4({
    color: '#fff',
    margin: 0,
})

const TokenHoverZone = styled.div({
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: '1px',
    left: '-1px',
    zIndex: 2,
    borderRadius: '50%',
    '&:hover': {
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.5)'
    }
})

export const TokenOption = ({token, handleAdd, updateContextMenu}: {token: Creature, handleAdd: (token: Creature) => void, updateContextMenu: (e: React.MouseEvent, creature: Creature) => void}) => {
    return (
        <TokenButton onClick={() => handleAdd(token)}>
            <TokenHoverZone onContextMenu={(e: MouseEvent) => updateContextMenu(e, token)}/>
            <TokenImage src={token.image}/>
            <TokenName>{token.name}</TokenName>
        </TokenButton>
    );
}