import OBR, { buildImage } from "@owlbear-rodeo/sdk";
import { Creature } from "../Helpers/CreatureTypes";
import { getAppID } from "../appId";

export interface CreatureScale {
    x: number,
    y: number,
}

export const DEFAULT_TOKEN_SIZE = 300
export const DEFAULT_SQUARE_SIZE = 150

export async function handleOBRAdd(creature: Creature, hideStats: boolean, hideToken: boolean = false) {
    const canAdd = await OBR.player.hasPermission("CHARACTER_CREATE")

    if (!canAdd) {
        alert('You cannot add tokens')
        return
    }

    const duplicateItems = await OBR.scene.items.getItems(
        (item) => item.metadata[`${getAppID()}/base-name`] === creature.name
    )

    let tokenName = creature.name;
    if (duplicateItems.length) {
        tokenName = `${creature.name} - ${duplicateItems.length + 1}`
    }

    const creatureScale = {
        x: creature.scaleX,
        y: creature.scaleY
    }

    const offsetX = creature.offsetX
    const offsetY = creature.offsetY

    const token = buildImage({
        width: DEFAULT_TOKEN_SIZE,
        height: DEFAULT_TOKEN_SIZE,
        url: `${creature.image}?_`,
        mime: "image/png",
      },
      { dpi: 300, offset: {x: (DEFAULT_TOKEN_SIZE / 2), y: (DEFAULT_TOKEN_SIZE / 2)} }
    )
    .plainText(tokenName)
    .build()
    
    token.layer = "CHARACTER";
    token.metadata[`${getAppID()}/char-sheet`] = creature.stats;
    token.metadata[`${getAppID()}/base-name`] = creature.name;
    token.metadata[`${getAppID()}/creature-scale`] = creatureScale as CreatureScale;
    if (hideStats) {
        token.metadata[`${getAppID()}/hidden`] = true
    }

    token.visible = !hideToken
    token.scale = creatureScale

    await OBR.scene.items.addItems([token])
}