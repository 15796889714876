import styled from "@emotion/styled"
import { MouseEvent } from "react"

const StyledContainer = styled.div(({positionX, positionY, isToggled}: {positionX: number, positionY: number, isToggled: boolean}) => ({
    position: 'absolute',
    top: `${positionY + 2}px`,
    left: `${positionX + 2}px`,
    backgroundColor: 'rgb(34, 38, 57)',
    width: '150px',
    display: isToggled ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    boxShadow: '-2px 4px 4px 6px rgba(0,0,0,0.5)'
}))

const StyledButton = styled.button({
    width: '100%',
    height: '30px',
    backgroundColor: 'rgb(30,34,49)',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgb(50,50,75)'
    },
    '& svg': {
        width: '20px',
        height: '20px',
        fill: 'white',
        stroke: 'white',
        marginRight: '10px'
    }
})

export const TokenContextMenu = ({
    rightClickItem,
    positionX,
    positionY,
    isToggled,
    buttons,
    contextMenuRef
}: {
    rightClickItem: any,
    positionX: number,
    positionY: number,
    isToggled: boolean,
    buttons: any,
    contextMenuRef: any
}) => {
    return (
        <StyledContainer ref={contextMenuRef} positionX={positionX} positionY={positionY} isToggled={isToggled}>
            {buttons && buttons.map((button: any, index: number) => {
                function handleClick(event: MouseEvent){
                    event.stopPropagation();
                    button.onClick(event, rightClickItem)
                }

                return (
                    <StyledButton key={index} onClick={handleClick}>
                        {button.icon()}
                        <span>{button.text}</span>
                    </StyledButton>
                )
            })}
        </StyledContainer>
    )
}