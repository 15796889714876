import react, {ReactElement, useState, useCallback, useEffect, useRef} from 'react'
import { Creature } from '../Helpers/CreatureTypes'
import { Container, CreatureGrid, StyledFooter, Wrapper } from './styled/SectionStyles';
import { TokenContextMenu } from '../CharacterSelector/TokenContextMenu';
import { TokenOption } from '../CharacterSelector/TokenOption';
import { useGetCreatures } from './Hooks/useGetCreatures';
import { ContextMenu } from './CustomCreatures';
import OBR from '@owlbear-rodeo/sdk';
import { LinkButton, PlusButton } from '../rec/svg';
import { handleOBRAdd } from '../Helpers/OBRHelpers';
import { ToggleSwitch } from '../CharacterSelector/ToggleSwitch';

export const Compendium = (): ReactElement => {
    const {data, isLoading, isFetching} = useGetCreatures()
    const [tokenHidden, setTokenHidden] = useState<boolean>(false)
    const creatures = data ?? []

    const contextMenuRef = useRef<HTMLDivElement|null>(null)
    const containerRef = useRef<HTMLDivElement|null>(null)
    const [contextMenu, setContextMenu] = useState<ContextMenu>({
        creature: null,
        position: {
            x: 0,
            y: 0,
        },
        toggled: false,
        buttons: null
    })

    const handleAdd = useCallback(async (creature: Creature) => {
        await handleOBRAdd(creature, true, tokenHidden)
        resetContextMenu()
    }, [tokenHidden])
    
    const handleContext = useCallback(async (e: react.MouseEvent<Element, MouseEvent>, creature: Creature) => {
        e.preventDefault()
        const isGM = await OBR.player.getRole() === 'GM';

        if (!isGM) {
            return
        }

        let contextMenuWidth = contextMenuRef.current?.getBoundingClientRect().width ? contextMenuRef.current?.getBoundingClientRect().width : 150
        const parentTop = containerRef.current?.parentElement?.getBoundingClientRect().top ?? 0
        const parentOffset = containerRef.current?.parentElement?.offsetTop ?? 40
        const isLeft = e.clientX < (window.innerWidth / 2)

        let x
        let y = e.clientY - (parentTop - parentOffset)

        if (isLeft) {
            x = e.clientX
        } else {
            x = e.clientX - contextMenuWidth
        }

        setContextMenu({
            creature,
            position: {
                x,
                y
            },
            toggled: true,
            buttons: [
                {
                    text: 'Add token',
                    icon: PlusButton,
                    onClick: () => handleAdd(creature)
                },
                {
                    text: 'Stat block',
                    icon: LinkButton,
                    onClick: () => { 
                        window.open(creature.stats, '_blank')
                        resetContextMenu()
                    }
                }
            ]
        })
    }, [handleAdd])

    function resetContextMenu() {
        setContextMenu({
            creature: null,
            position: {
                x: 0,
                y: 0,
            },
            toggled: false,
            buttons: null
        })
    }

    useEffect(() => {
        function handler(e: any) {
            if (contextMenuRef.current) {
                if(!contextMenuRef.current.contains(e.target)) {
                    resetContextMenu()
                }
            }
        }

        document.addEventListener('click', handler)
        
        return () => {
            document.removeEventListener('click', handler)
        }
    })
    
    return (
        <Container>
            <Wrapper isLoading={isLoading || isFetching}>
                <CreatureGrid>
                    {creatures.map((creature: Creature) => <TokenOption key={creature.id} token={creature} handleAdd={handleAdd} updateContextMenu={handleContext}/>)}
                </CreatureGrid>
            </Wrapper>
            <StyledFooter>
                <ToggleSwitch label="Hide Token" update={setTokenHidden} isActive={tokenHidden}/>
            </StyledFooter>
            <TokenContextMenu 
                rightClickItem={contextMenu.creature}
                positionX={contextMenu.position.x}
                positionY={contextMenu.position.y}
                isToggled={contextMenu.toggled} 
                buttons={contextMenu.buttons} 
                contextMenuRef={contextMenuRef}
            />
        </Container>
    )
}