import { useQuery } from "@tanstack/react-query";
import { API_ROOT } from "../../Helpers/Endpoints";
import { Creature, SmallCreature, MediumCreature, LargeCreature, HugeCreature, GargantuanCreature } from "../../Helpers/CreatureTypes";

export const GetCreaturesQuery = 'creatures/compendium'

interface CreatureResource {
    id: number,
    name: string,
    size: string,
    type: string,
    image: string,
    stats: string,
}

export const useGetCreatures = () => {
    return useQuery({
        queryKey: [GetCreaturesQuery],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            return fetch(`${API_ROOT}/v1/creatures`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "cache-control": "no-cache"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (!data?.items) {
                    return [];
                }

                const creatures: Creature[] = [];
                data.items.forEach((creature: CreatureResource) => {
                    let filteredCreature = {};
                    switch (creature.size) {
                        case 'small':
                            filteredCreature = SmallCreature
                            break
                        case 'medium':
                            filteredCreature = MediumCreature
                            break
                        case 'large':
                            filteredCreature = LargeCreature
                            break
                        case 'huge':
                            filteredCreature = HugeCreature
                            break
                        case 'gargantuan':
                            filteredCreature = GargantuanCreature
                            break
                        default:
                            filteredCreature = MediumCreature
                            break
                    }

                    creatures.push({
                        ...filteredCreature,
                        id: creature.id,
                        name: creature.name,
                        size: creature.size,
                        type: creature.type,
                        image: creature.image,
                        stats: creature.stats
                    } as Creature)
                })

                return creatures;
            })
        }
    })
}